<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <!-- <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Select v-model="query.dynamicFlag" size="small" clearable placeholder="是否开启动态定价">
          <Option value="true">是</Option>
          <Option value="false">否</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Select v-model="query.openDynamicVerify" size="small" clearable placeholder="是否开启动态核销期">
          <Option value="true">是</Option>
          <Option value="false">否</Option>
        </Select>
      </i-col> -->
      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Select v-model="query.productType" size="small" clearable placeholder="选择商品类型">
          <Option :value="1">实物商品</Option>
          <Option :value="2">电子卡券</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Select v-model="query.itemState" size="small" clearable placeholder="选择商品状态">
          <Option v-for="status in statusArray" :key="status.id" :value="status.id">{{status.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Select v-model="query.sortType" size="small" clearable placeholder="选择排序方式">
          <Option :value="1">核销开始时间升序</Option>
          <Option :value="2">核销开始时间降序</Option>
          <Option :value="3">核销结束时间升序</Option>
          <Option :value="4">核销结束时间降序</Option>
        </Select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <i-input
          size="small"
          v-model="query.keyWord"
          placeholder="关键字：商品名称、商品编码"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="3">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="initPageData"
          >搜索</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>

    <Modal v-model="commodityBatchEditModal" title="批量编辑商品信息" width="400">
      <Row class="m-b-20 m-t-10">
        <i-col span="6" ><span class="title">是否动态定价 </span ></i-col >
        <i-col span="18" >
          <RadioGroup v-model="updateDynamicFlag">
              <Radio label="true">是</Radio>
              <Radio label="false">否</Radio>
          </RadioGroup>
        </i-col>
      </Row>

      <Row class="m-b-10 m-t-20">
        <i-col span="6" ><span class="title">预计下架日期 </span ></i-col >
        <i-col span="18" >
          <DatePicker v-model="updateExpectDownDate" type="date" placeholder="请选择预计下架日期" size="small"></DatePicker>
        </i-col >
      </Row>

      <div slot="footer">
        <Button type="text" class="m-r-5" @click="commodityBatchEditModal = false">取消</Button>
        <Button type="primary" :disabled="updateDynamicFlag===null" @click="handleBatchUpdateCommodity">确定</Button>
      </div>
    </Modal>

    <EditComdityModal ref="editComdityModal" @on-edit-success="onSearchData" @on-add-success="initPageData"/>

    <CommodityHistoryModal ref="commodityHistoryModal"/>
  </div>
</template>

<script>
import EditComdityModal from './EditComdityModal'
import CommodityHistoryModal from './CommodityHistoryModal'

import { getTimeDifference } from '@/utils/dateFormat'
// import { ParseDate } from '@/utils/dateFormat'
import { listProducts, listItemStatus } from '@/api/displace/productDutch'

export default {
  components: {
    CommodityHistoryModal, EditComdityModal
  },
  data () {
    return {
      statusArray: [],
      total: 0,
      query: {
        dynamicFlag: null,
        openDynamicVerify: null,
        productType: null,
        itemState: null,
        pageNumber: 1,
        pageSize: 15,
        keyWord: ''
      },

      tableData: [],
      columnManagedata: [
        { title: '商家名称', key: 'businessName', width: 120 },
        { title: 'union端合同里面的商品名称', key: 'productName' },
        { title: '有赞（mall4j）的商品名称', key: 'youZanItemTitle', width: 300 },
        { title: '商品类型', key: 'productTypeName', width: 120 },
        { title: '商品状态', key: 'itemStateName', width: 120 },
        { title: '合同内的核销有效期（开始）', key: 'validityStartDate', width: 120 },
        { title: '合同内的核销有效期（结束）', key: 'validityEndDate', width: 120 },
        {
          title: '核销剩余天数',
          key: 'validityEndDate',
          width: 120,
          render: (h, params) => {
            const spans = []
            const days = getTimeDifference(Date.now(), params.row.validityEndDate)
            if (parseInt(days.split('天')[0]) < 30) {
              if (parseInt(days.split('天')[0]) <= 0) {
                spans.push(h('span', {
                  style: {
                    marginRight: '5px',
                    color: '#ef4f4f'
                  }
                }, '已过核销期'))
              } else {
                spans.push(h('span', {
                  style: {
                    marginRight: '5px',
                    color: '#FA8B59'
                  }
                }, days))
              }
            } else {
              spans.push(h('span', {
                style: {
                  marginRight: '5px',
                  color: '#44bd32'
                }
              }, days))
            }

            return h('div', spans)
          }
        },
        { title: '合同里面的商品总库存数', key: 'totalNum', width: 120 },
        { title: '商品上架数', key: 'listNum', width: 100 },
        { title: '置换单价', key: 'replacementPrice', width: 100 },
        { title: '已售数汇总', key: 'totalSoldNum', width: 100 },
        { title: '核销数汇总', key: 'totalVerifiedNum', width: 100 }

        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 120,
        //   fixed: 'right',
        //   render: (h, params) => {
        //     return h('div', [
        //       h('a', {
        //         style: {
        //           'margin-right': '5px'
        //         },
        //         on: {
        //           click: () => {
        //             this.$refs.editComdityModal.showModal(params.row)
        //           }
        //         }
        //       }, '编辑'),
        //       h('a', {
        //         on: {
        //           click: () => {
        //             this.$refs.commodityHistoryModal.showHistoryModal(params.row)
        //           }
        //         }
        //       }, '历史记录')
        //     ])
        //   }
        // }
      ],

      commodityBatchEditModal: false,
      updateDynamicFlag: null,
      updateExpectDownDate: null,
      selectCommodityIds: []
    }
  },
  mounted () {
    this.initPageData()
    // 获取商品状态清单
    listItemStatus().then(res => {
      this.statusArray = res
    })
  },
  methods: {
    initPageData () {
      this.query.pageNumber = 1
      this.onSearchData()
    },
    onSearchData () {
      listProducts(this.query).then((res) => {
        this.tableData = res.list
        this.total = res.totalRow
        this.query.pageNumber = res.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.onSearchData()
    },
    batchUpdate () {
      this.commodityBatchEditModal = true
      this.updateDynamicFlag = null
      this.updateExpectDownDate = null
    },
    selectionChange (selection) {
      // 当前选中的列
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 当前表格内的所有列数据
      const tableIds = this.tableData.map(item => {
        return item.id
      })

      tableIds.forEach(element => {
        if (selectionIds.includes(element)) {
          // 判断是否已在列表，不在则加入
          if (!this.selectCommodityIds.includes(element)) {
            this.selectCommodityIds.push(element)
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (this.selectCommodityIds.includes(element)) {
            this.selectCommodityIds.splice(this.selectCommodityIds.indexOf(element), 1)
          }
        }
      })
    },
    handleBatchUpdateCommodity () {
      // const updateCommodityBean = {
      //   productIds: this.selectCommodityIds.join(','),
      //   dynamicFlag: this.updateDynamicFlag,
      //   expectDownDate: this.updateExpectDownDate ? ParseDate(this.updateExpectDownDate) : null
      // }

      // BatchUpdateProduct(updateCommodityBean).then(res => {
      //   if (res === 'SUCCESS') {
      //     this.selectCommodityIds = []
      //     this.onSearchData()
      //     this.$Notice.success({ desc: '批量编辑商品信息成功' })
      //   } else {
      //     this.$Notice.error({ desc: res })
      //   }

      //   this.commodityBatchEditModal = false
      // })
    }
  }
}
</script>
